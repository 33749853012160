export enum EMessage {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export interface IMessage {
  type: EMessage;
  message: string;
  onClose?: () => void;
  id?: number;
}
