import { defineAsyncComponent } from 'vue';
import useModal from '@/composables/useModal';
import { IModalOptions } from '@/components/layout/modal';

const { push } = useModal();

const createOpener =
  (component: Promise<any>, options?: IModalOptions) =>
  <T>(state?: any): Promise<T> =>
    push(
      defineAsyncComponent(() => component),
      state,
      options
    );

export const ConfirmCode = createOpener(
  import(/* webpackPrefetch: true */ './utils/ConfirmCode.vue')
);

export const EditProfile = createOpener(
  import(/* webpackPrefetch: true */ './profile/EditProfile.vue')
);

export const EditCompany = createOpener(
  import(/* webpackPrefetch: true */ './profile/EditCompany.vue')
);

/**
 * Order
 */
export const CancelOrder = createOpener(
  import(/* webpackPrefetch: true */ './orders/CancelOrder.vue')
);
export const CloseDate = createOpener(
  import(/* webpackPrefetch: true */ './orders/CloseDate.vue'),
  {
    width: '400px',
  }
);
export const ConfirmDate = createOpener(
  import(/* webpackPrefetch: true */ './orders/ConfirmDate.vue'),
  {
    width: '400px',
  }
);
export const DisputDate = createOpener(
  import(/* webpackPrefetch: true */ './orders/DisputDate.vue'),
  {
    width: '400px',
  }
);

export const ExecutorConfirm = createOpener(
  import(/* webpackPrefetch: true */ './request/ExecutorConfirm.vue')
);

export const RequestConfirm = createOpener(
  import(/* webpackPrefetch: true */ './request/RequestConfirm.vue')
);

export const OfferConfirm = createOpener(
  import(/* webpackPrefetch: true */ './request/OfferConfirm.vue')
);

export const BookingAlert = createOpener(
  import(/* webpackPrefetch: true */ './request/BookingAlert.vue'),
  {
    width: '400px',
  }
);

// Utils
export const PlaceSelector = createOpener(
  import(/* webpackPrefetch: true */ './utils/PlaceSelector.vue')
);

export const DatePickerRange = createOpener(
  import(/* webpackPrefetch: true */ './utils/DatePickerRange.vue')
);

export const RequestSignification = createOpener(
  import(/* webpackPrefetch: true */ './utils/RequestSignification.vue'),
  {
    width: '700px',
  }
);

export const RequestPayment = createOpener(
  import(/* webpackPrefetch: true */ './utils/RequestPayment.vue')
);

export const CrewBooking = createOpener(
  import(/* webpackPrefetch: true */ './utils/CrewBooking.vue'),
  {
    width: '1000px',
  }
);
