import { App } from 'vue';
import Button from '@/components/basic/Button.vue';
import Input from '@/components/basic/input/Input.vue';
import Select from '@/components/basic/Select.vue';
import Item from '@/components/basic/item/Item.vue';
import Summarizer from '@/components/basic/Summarizer.vue';

/* eslint-disable */
declare module '*.vue' {
  export interface GlobalComponents {
    'i-button': typeof Button;
    'i-input': typeof Input;
    'i-select': typeof Select;
    'i-item': typeof Item;
    'i-summarizer': typeof Summarizer;
  }
}

export default {
  install(app: App<Element>): void {
    app.component('i-button', Button);
    app.component('i-input', Input);
    app.component('i-select', Select);
    app.component('i-item', Item);
    app.component('i-summarizer', Summarizer);
  },
};
