import { NavigationGuardWithThis } from 'vue-router';
import i18n from '@/plugins/i18n';

const localeGuard: NavigationGuardWithThis<any> = async (to, from, next) => {
  let { locale } = to.params;
  if (!locale) {
    if (to.path.match(/^(\/(uk|ru)){2,}/)) {
      /**
       * this guard re-writes url and prevents default 404 handling
       * so it is need to handle 404 manually
       */
      next('/not-found');
    }
    locale = i18n.global.locale.value;
    next(`/${locale}${to.fullPath}`);
  } else {
    i18n.global.locale.value = locale as string;
    next();
  }
};

export default localeGuard;
