import { IPaginationData, IPaginationParams } from '@/interfaces/common';
import {
  IDron,
  IEntity,
  IPerformanceRange,
  IStatus,
} from '@/interfaces/entities';
import { BaseAPI } from './base.api';

class DronAPI extends BaseAPI {
  controller = '/drone';

  add(data: Partial<IDron>) {
    return this._post<IDron>('', { data });
  }

  get(params: IPaginationParams = { page: 0, size: 0, availableOnly: true }) {
    return this._get<IPaginationData<IDron>>('', { params });
  }

  update(uid: string, data: Partial<IDron>) {
    return this._patch<IDron>(`/${uid}`, { data });
  }

  getById(uid: string) {
    return this._get(`/${uid}`);
  }

  delete(uid: string) {
    return this._delete(`/${uid}`);
  }

  performanceRanges() {
    return this._get<IPerformanceRange[]>('/performanceRanges');
  }

  statuses() {
    return this._get<IStatus[]>('/statuses');
  }

  vendors() {
    return this._get<IEntity[]>('/vendors');
  }

  vendorsModels(vendor: string) {
    return this._get<IEntity[]>(`/vendors/${vendor}/models`);
  }

  imageAdd(uid: string, data: FormData) {
    return this._post<any>(`/${uid}/image/add`, { data });
  }

  imageRemove(uid: string) {
    return this._post<any>(`/${uid}/image/remove`);
  }
}

const api = new DronAPI();
export default api;
