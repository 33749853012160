import crypto from 'crypto-js';
import { IBaseAuthResponse } from '@/interfaces/models/auth';

const key = navigator.userAgent;

export const setToken = (data: IBaseAuthResponse, remember?: boolean): void => {
  const encrypted = crypto.AES.encrypt(
    JSON.stringify({
      ...data,
      accessTokenExpires: data.accessTokenExpires * 1000 + new Date().getTime(),
    }),
    key
  ).toString();
  if (remember) {
    localStorage.setItem('_data', encrypted);
  } else {
    sessionStorage.setItem('_data', encrypted);
  }
};

export const getToken = (): IBaseAuthResponse | null => {
  try {
    const data =
      localStorage.getItem('_data') || sessionStorage.getItem('_data');
    if (!data) return null;
    const bytes = crypto.AES.decrypt(data, key);
    const original = bytes.toString(crypto.enc.Utf8);

    return JSON.parse(original);
  } catch {
    return null;
  }
};

export const isExpired = (): boolean => {
  const tokenData = getToken();
  if (!tokenData) return false;
  return !!(
    tokenData.accessTokenExpires < Date.now() &&
    (tokenData.refreshToken || tokenData.newRefreshToken)
  );
};

export const clearToken = (): void => {
  localStorage.removeItem('_data');
  sessionStorage.removeItem('_data');
};
