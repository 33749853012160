import Router from './router.vue';

export default [
  {
    path: 'auth',
    component: Router,
    children: [
      {
        path: 'sign-in',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "auth-sign-in" */ './pages/SignIn.vue'),
        meta: {
          withSignUp: true,
          back: '/',
          header: 'page.signIn',
        },
      },
      {
        path: 'sign-up',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "auth-sign-up" */ './pages/SignUp.vue'),
        meta: {
          withSignIn: true,
          back: '/',
          header: 'page.signUp',
        },
      },
      {
        path: 'pass-recovery',
        component: (): Promise<typeof import('*.vue')> =>
          import(
            /* webpackChunkName: "auth-pass-recovery" */ './pages/PasswordRecovery.vue'
          ),
        meta: {
          withSignUp: true,
          back: '/',
          header: 'page.passwordRecovery',
        },
      },
    ],
  },
];
