import { IEntity, IProfileSettings, IUser } from '@/interfaces/entities';
import {
  IEmailDto,
  ISaveCompanyDto,
  IUpdateProfileDto,
  ILanguageDto,
} from '@/interfaces/models/profile';
import { BaseAPI } from './base.api';

class ProfileAPI extends BaseAPI {
  controller = '/profile';

  update(data: IUpdateProfileDto) {
    return this._post<IUser>('/', { data });
  }

  companyGet() {
    return this._get<any>('/company');
  }

  companyAdd(data: ISaveCompanyDto) {
    return this._post<IUser>('/company', { data });
  }

  companyImageAdd(data: ISaveCompanyDto) {
    return this._post<any>('/company/image', { data });
  }

  emailsAdd(data: IEmailDto) {
    return this._post<void>('/emails/add', { data });
  }

  emailsRemove(data: IEmailDto) {
    return this._post<void>('/emails/remove', { data });
  }

  emails() {
    return this._get<IEmailDto[]>('/emails');
  }

  companyTypes() {
    return this._get<IEntity[]>('/companyTypes');
  }

  languages(data: ILanguageDto) {
    return this._post<void>('/language', { data });
  }

  imageAdd(data: FormData) {
    console.log(data);
    return this._post<any>('/image/add', { data });
  }

  balance() {
    return this._get<{ balance: string }>('/balance');
  }

  settings() {
    return this._get<IProfileSettings>('/settings');
  }
}

const api = new ProfileAPI();
export default api;
