import { createI18n } from 'vue-i18n';
import uk from './uk.json';
import ru from './ru.json';

type MessageSchema = typeof uk;
const messages: Record<'uk' | 'ru', MessageSchema> = { uk, ru };

const i18n = createI18n({
  legacy: false,
  locale: 'uk',
  fallbackLocale: 'uk',
  messages,
});

export default i18n;
