import useOrders from './pages/order/context';
import useOffer from './pages/offer/context';
import { RouteRecordRaw } from 'vue-router';
const orderContext = useOrders();
const offerContext = useOffer();

export default [
  {
    path: 'orders',
    component: (): Promise<typeof import('*.vue')> =>
      import(/* webpackChunkName: "orders" */ './pages/index/index.vue'),
    meta: {
      header: 'page.order',
      withAuth: true,
    },
  },
  {
    path: 'orders/:requestUid',
    component: (): Promise<typeof import('*.vue')> =>
      import(
        /* webpackChunkName: "orders-request-uid" */ './pages/order/index.vue'
      ),
    meta: {
      header: 'page.orderAdd',
      withAuth: true,
    },
    async beforeEnter(to, from, next) {
      await orderContext.getOrder(to.params.requestUid as string);

      if (!orderContext.orders.value.length)
        return next(`/offer/${to.params.requestUid}`);
      next();
    },
  },
  {
    path: 'offer/:requestUid',
    component: (): Promise<typeof import('*.vue')> =>
      import(
        /* webpackChunkName: "offer-request-uid" */ './pages/offer/index.vue'
      ),
    meta: {
      header: 'page.orderAdd',
      withAuth: true,
    },
    async beforeEnter(to, from, next) {
      await Promise.all([
        orderContext.getOrder(to.params.requestUid as string),
        offerContext.getRequest(to.params.requestUid as string),
      ]);

      if (!orderContext.orders.value.length) {
        await offerContext.getOffer(to.params.requestUid as string);
        offerContext.loading.value = false;
        return next();
      }
      next(`/orders/${to.params.requestUid}`);
    },
  },
] as RouteRecordRaw[];
