import { IUser } from '@/interfaces/entities';
import {
  IBaseAuthResponse,
  ICheckingCodeDto,
  ILoginPasswordDto,
  ILoginPhoneDto,
  IRefreshTokenDto,
  IResetPasswordDto,
  ISetPasswordDto,
  IUpdateUserDto,
} from '@/interfaces/models/auth';
import { setToken } from '@/utils/jwt';
import { BaseAPI } from './base.api';

class AuthAPI extends BaseAPI {
  controller = '/auth';

  login(data: ILoginPasswordDto) {
    return this._post<any>('/login', { data });
  }

  sendCode(data: ILoginPhoneDto) {
    return this._post<any>('/sendCode', { data });
  }

  checkCode(data: ICheckingCodeDto) {
    return this._post<IBaseAuthResponse>('/checkCode', { data });
  }

  refresh(data: IRefreshTokenDto) {
    return this._post<IBaseAuthResponse>('/refresh', { data }).then(setToken);
  }

  me() {
    return this._get<IUser>('/me');
  }

  updateProfile(data: Partial<IUpdateUserDto>) {
    return this._patch<IUser>('/updateProfile', { data });
  }

  setPassword(data: ISetPasswordDto) {
    return this._patch<any>('/setPassword', { data });
  }

  resetPassword(data: IResetPasswordDto) {
    return this._post<any>('/resetPassword', { data });
  }
}

const api = new AuthAPI();
export default api;
