import { reactive } from 'vue';
import { EMessage, IMessage } from '@/components/layout/snack-bar';

export const mesagges: IMessage[] = reactive([]);

function remove(config: IMessage) {
  const index = mesagges.indexOf(config);
  mesagges.splice(index, 1);
  config.onClose?.();
}
function add(config: IMessage) {
  if (!config.message) return;
  mesagges.unshift({ ...config, id: Math.random() });
  setTimeout(() => {
    remove(config);
  }, 7000);
}
function requestError(error: any) {
  add({
    message: `${error.message}. ${error.response?.data?.error?.message || ''}`,
    type: EMessage.ERROR,
  });
}
function error(message: string, onClose?: () => void) {
  add({ message, type: EMessage.ERROR, onClose });
}
function success(message: string, onClose?: () => void) {
  add({ message, type: EMessage.SUCCESS, onClose });
}
function info(message: string, onClose?: () => void) {
  add({ message, type: EMessage.INFO, onClose });
}
function warning(message: string, onClose?: () => void) {
  add({ message, type: EMessage.WARNING, onClose });
}

export default () => ({
  mesagges,
  remove,
  add,
  requestError,
  error,
  success,
  info,
  warning,
});
