import { IPaginationData, IPaginationParams } from '@/interfaces/common';
import { ICar, IEntity, IStatus } from '@/interfaces/entities';
import { BaseAPI } from './base.api';

class CarAPI extends BaseAPI {
  controller = '/car';

  add(data: Partial<ICar>) {
    return this._post<ICar>('', { data });
  }

  get(params: IPaginationParams = { page: 0, size: 0, availableOnly: true }) {
    return this._get<IPaginationData<ICar>>('', { params });
  }

  update(uid: string, data: Partial<ICar>) {
    return this._patch<ICar>(`/${uid}`, { data });
  }

  getById(uid: string) {
    return this._get(`/${uid}`);
  }

  delete(uid: string) {
    return this._delete(`/${uid}`);
  }

  colors() {
    return this._get<IEntity[]>('/colors');
  }

  fuelTypes() {
    return this._get<IEntity[]>('/fuelTypes');
  }

  makes() {
    return this._get<IEntity[]>('/makes');
  }

  makesModels(vendor: string) {
    return this._get<IEntity[]>(`/makes/${vendor}/models`);
  }

  statuses() {
    return this._get<IStatus[]>(`/statuses`);
  }

  imageAdd(uid: string, data: FormData) {
    return this._post<any>(`/${uid}/image/add`, { data });
  }

  imageRemove(uid: string) {
    return this._post<any>(`/${uid}/image/remove`);
  }
}

const api = new CarAPI();
export default api;
