import { computed, onMounted, onUnmounted, ref } from 'vue';

const width = ref(window.innerWidth);
const isDesktop = computed(() => {
  return width.value > 1200;
});

function updateWidth() {
  width.value = window.innerWidth;
}

export default () => {
  onMounted(() => {
    window.addEventListener('resize', updateWidth);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateWidth);
  });

  return {
    width,
    isDesktop,
  };
};
