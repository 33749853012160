import { EStatuses, ICrew, IEntity, ILocation, IUser } from '../entities';

export enum ERequestStatusesUpdater {
  DocumentsSigned = 'documentsSigned',
  PrepaymentPaid = 'prepaymentPaid',
  PrepaymentAccepted = 'prepaymentAccepted',
  PaymentRequired = 'paymentRequired',
  PaymentPaid = 'paymentPaid',
  PaymentAccepted = 'paymentAccepted',
  Cancel = 'cancel',
  Accept = 'accept',
  Dispute = 'dispute',
}

export enum ERequestStatuses {
  New = 'new',
  Offered = 'offered',
  Ordered = 'ordered',
  WorkInProgress = 'workInProgress',
  WorkAcceptance = 'workAcceptance',
  Cancelling = 'cancelling',
  Cancelled = 'cancelled',
  Done = 'done',
  Dispute = 'dispute',
}

export interface IRequest {
  cropId: string;
  cropManagementOperationId: string;
  applicationRate: number;
  area: number;
  startAt: string;
  finishAt: string;
  address: string;
  latitude: number;
  longitude: number;
  numberOfFields: number;
  user: IUser;
  status: ERequestStatuses;

  crop: IEntity;
  cropManagementOperation: IEntity;
  point: {
    crs: any;
    type: string;
    coordinates: number[];
  };
  totalCost: number;
  totalApplicationCost: number;
  totalFareCost: number;
  uid: string;
  id: number;
  updatedAt: string;
  number: number;
}

export interface IRequestSuggestionItem {
  crews: ICrew[];
  crewsTotal: number;
  applicationCost: number;
  fareCost: number;
  days: number;
  distance: number;
  isComplete: boolean;
  location: ILocation;
  partner: IUser;
  priceFare: string;
  priceIdle: string;
  pricePerSquare: number;
  squarePerDay: number;
  totalCost: number;
  workingHours: number;
  area: number;
  startAt: string;
  finishAt: string;
}

export interface IRequestResponse {
  items: IRequestSuggestionItem[];
  requestId: string;
  orderMaximalAreaOverheadPercent: number;
  orderMinimalAreaPercent: number;
}

export interface IStatusChange {
  newStatus: string;
  oldStatus: string;
  updated: string[];
}
