import { IPaginationData } from '@/interfaces/common';
import { IDocument } from '@/interfaces/entities';
import {
  ICreateOrder,
  ICreateOrderResponse,
  IGetOrdersParams,
  IOrder,
  IOrderClose,
  IOrderConfirm,
  IStatistics,
  IWorklogItem,
} from '@/interfaces/models/order';
import { BaseAPI } from './base.api';

class OrderAPI extends BaseAPI {
  controller = '/order';

  get(params: IGetOrdersParams) {
    return this._get<IPaginationData<IOrder>>('', { params });
  }

  add(data: ICreateOrder) {
    return this._post<ICreateOrderResponse>('', { data });
  }

  getById(uid: string) {
    return this._get<IOrder>(`/${uid}`);
  }

  getByIdDate(uid: string, date: string) {
    return this._get(`/${uid}/day/${date}`);
  }

  documents(uid: string) {
    return this._get<IDocument[]>(`/${uid}/documents`);
  }

  document(orderUid: string, documentUid: string) {
    return this._post(`/${orderUid}/documents/${documentUid}`);
  }

  orderClose(uid: string, date: string, data: IOrderClose) {
    return this._post<IWorklogItem>(`/${uid}/day/${date}/close`, { data });
  }

  orderConfirm(uid: string, date: string, data: IOrderConfirm) {
    return this._post<IWorklogItem>(`/${uid}/day/${date}/confirm`, { data });
  }

  statistics() {
    return this._get<IStatistics>(`/statistics`);
  }
}

const api = new OrderAPI();
export default api;
