import Router from './router.vue';
import ParkRouter from './pages/park/router';
import OrderRouter from './pages/orders/router';

export default [
  {
    path: '',
    component: Router,
    meta: {
      withAuth: true,
    },
    children: [
      {
        path: '',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "main" */ './pages/main/index.vue'),
        meta: {
          header: 'page.main',
        },
      },
      {
        path: 'bills',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "bills" */ './pages/bills/index.vue'),
        meta: {
          header: 'page.bills',
        },
      },
      {
        path: 'documents',
        component: (): Promise<typeof import('*.vue')> =>
          import(
            /* webpackChunkName: "documents" */ './pages/documents/index.vue'
          ),
        meta: {
          header: 'page.documents',
        },
      },
      {
        path: 'request',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "request" */ './pages/request/index.vue'),
        meta: {
          header: 'page.orderAdd',
          clientOnly: true,
          forApprovedClients: true,
        },
      },
      {
        path: 'booking',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "booking" */ './pages/booking/index.vue'),
        meta: {
          header: 'page.booking',
          partnerOnly: true,
        },
      },
      {
        path: 'profile',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "profile" */ './pages/profile/index.vue'),
        meta: {
          header: 'page.profileSettings',
          withAuth: true,
        },
      },
      ...ParkRouter,
      ...OrderRouter,
    ],
  },
];
