import { IPaginationData, IPaginationParams } from '@/interfaces/common';
import {
  IBooking,
  ICrew,
  IDron,
  IPerformanceRange,
  IStaff,
  IStatus,
} from '@/interfaces/entities';
import { BaseAPI } from './base.api';

class DronAPI extends BaseAPI {
  controller = '/crew';

  add(data: Partial<ICrew>) {
    return this._post<ICrew>('', { data });
  }

  get(params: IPaginationParams = { page: 0, size: 20 }) {
    return this._get<IPaginationData<ICrew>>('', { params });
  }

  update(uid: string, data: Partial<ICrew>) {
    return this._patch<ICrew>(`/${uid}`, { data });
  }

  getById(uid: string) {
    return this._get(`/${uid}`);
  }

  delete(uid: string) {
    return this._delete(`/${uid}`);
  }

  performanceRanges() {
    return this._get<IPerformanceRange[]>('/performanceRanges');
  }

  statuses() {
    return this._get<IStatus[]>(`/statuses`);
  }

  getDrone(uid: string) {
    return this._get<IDron[]>(`/${uid}/drones`);
  }

  addtDrone(uid: string, droneId: string) {
    return this._post(`/${uid}/drones`, { data: { droneId } });
  }

  deleteDrone(uid: string, droneId: string) {
    return this._delete(`/${uid}/drones/${droneId}`);
  }

  getStaff(uid: string) {
    return this._get<IStaff[]>(`/${uid}/staff`);
  }

  addStaff(uid: string, staffId: string) {
    return this._post(`/${uid}/staff`, { data: { staffId } });
  }

  getBooking(uid: string) {
    return this._get<IBooking[]>(`/${uid}/booking`);
  }

  setBooking(uid: string, dates: string[]) {
    return this._post(`/${uid}/booking`, { data: { dates } });
  }

  deleteStaff(uid: string, staffId: string) {
    return this._delete(`/${uid}/staff/${staffId}`);
  }

  imageAdd(uid: string, data: FormData) {
    return this._post<any>(`/${uid}/image/add`, { data });
  }

  imageRemove(uid: string) {
    return this._post<any>(`/${uid}/image/remove`);
  }
}

const api = new DronAPI();
export default api;
