import { IToggleButtonItem } from '@/components/basic/toggle-buttons';
import {
  ICar,
  ICrew,
  IDron,
  IEntity,
  ILocation,
  IStaff,
} from '@/interfaces/entities';
import { computed, reactive, ref, watch } from 'vue';
import DropAPI from '@/api/dron.api';
import CarAPI from '@/api/car.api';
import StaffAPI from '@/api/staff.api';
import LocationAPI from '@/api/location.api';
import CrewAPI from '@/api/crew.api';
import { IPaginationData } from '@/interfaces/common';
import useMessage from '@/composables/useMessage';

export enum EMembers {
  Drones = 'Drones',
  Personnel = 'Personnel',
  Transports = 'Transports',
  Crews = 'Crews',
  Locations = 'Locations',
  Price = 'Price',
}

const { requestError } = useMessage();
const dronList = ref<IPaginationData<IDron>>();
const carList = ref<IPaginationData<ICar>>();
const staffList = ref<IPaginationData<IStaff>>();
const locationList = ref<IPaginationData<ILocation>>();
const crewList = ref<IPaginationData<ICrew>>();

export interface IDashboardParkState<T = IEntity> {
  sections: IToggleButtonItem[];
  currentSection: EMembers;
  edittingItem: null | T;
  dronList: IPaginationData<IDron>;
  carList: IPaginationData<ICar>;
  staffList: IPaginationData<IStaff>;
  locationList: IPaginationData<ILocation>;
  crewList: IPaginationData<ICrew>;
  page: number;
  size: number;
  total: number;
}

const state = reactive({
  sections: computed(
    () =>
      [
        {
          uid: EMembers.Drones,
          name: 'page.park.label.drone',
          amount: dronList.value?.total,
        },
        {
          uid: EMembers.Personnel,
          name: 'page.park.label.stuff',
          amount: staffList.value?.total,
        },
        {
          uid: EMembers.Transports,
          name: 'page.park.label.transport',
          amount: carList.value?.total,
        },
        {
          uid: EMembers.Crews,
          name: 'page.park.label.crew',
          amount: crewList.value?.total,
        },
        {
          uid: EMembers.Locations,
          name: 'page.park.label.techLocation',
          amount: locationList.value?.total,
        },
        {
          uid: EMembers.Price,
          name: 'page.park.label.price',
        },
      ] as IToggleButtonItem[]
  ),
  currentSection: localStorage.getItem('currentParkSection') || EMembers.Drones,
  edittingItem: null,
});

watch(
  () => state.currentSection,
  (section: string) => {
    localStorage.setItem('currentParkSection', section);
  }
);

export interface IDashboardParkContext<T = IEntity> {
  state: IDashboardParkState<T>;
  getItems: () => void;
}

export default {
  state,
  getItems() {
    const params = {
      page: 0,
      size: 2,
    };
    Promise.all([
      DropAPI.get(params),
      CarAPI.get(params),
      StaffAPI.get(params),
      LocationAPI.get(params),
      CrewAPI.get(params),
    ])
      .then(([_dronList, _carList, _staffList, _locationList, _crewList]) => {
        dronList.value = _dronList;
        carList.value = _carList;
        staffList.value = _staffList;
        locationList.value = _locationList;
        crewList.value = _crewList;
      })
      .catch(requestError);
  },
} as IDashboardParkContext;
