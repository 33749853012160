<template>
  <div class="summarizer">
    <div class="inner">
      <slot name="default"></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<style lang="scss">
.summarizer {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: var(--line);

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 12px;
    max-height: 100%;
    justify-content: center;
  }

  &[horizontal] .inner {
    flex-direction: row;
  }

  &[transparent] {
    background-color: var(--transparent);

    .inner {
      padding: 0;
    }
  }

  &[red] {
    background: var(--red);
  }

  &[green] {
    background: var(--green);
  }

  &[border] {
    background-color: var(--transparent);
    border: 2px solid var(--line);

    &[red] {
      border-color: var(--red);
    }

    &[green] {
      border-color: var(--green);
    }
  }

  [label] {
    font-size: 12px;
    color: var(--gray);
    text-align: center;

    &:not(:first-of-type) {
      margin-top: 8px;
    }

    &[dark] {
      color: var(--dark);
    }

    &[white] {
      color: var(--white);
    }
  }
  [value] {
    font-size: 20px;
    font-weight: 700;
    color: var(--green);
    text-align: center;

    &[dark] {
      color: var(--dark);
    }

    &[white] {
      color: var(--white);
    }
  }
}
</style>
