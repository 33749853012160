import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from 'vue-router';
import PageNotFound from '@/views/404.vue';
import AuthRoutes from '@/views/auth/router';
import DashboardRoutes from '@/views/client/router';
import authGuard from './guards/authGuard';
import localeGuard from './guards/localeGuard';
import partnerVerificationGuard from './guards/partnerVerificationGuard';
import clientVerificationGuard from './guards/clientVerificationGuard';
import roleGuard from './guards/roleGuard';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale(uk|ru)?',
    component: RouterView,
    children: [
      ...AuthRoutes,
      ...DashboardRoutes,
      { path: 'not-found', component: PageNotFound },
    ],
  },
  { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(localeGuard);
router.beforeEach(authGuard);
router.beforeEach(partnerVerificationGuard);
router.beforeEach(clientVerificationGuard);
router.beforeEach(roleGuard);

export default router;
