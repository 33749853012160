/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { reactive, readonly } from 'vue';
import { IModal, IModalOptions } from '@/components/layout/modal';

const state: IModal = reactive({
  screen: null,
  display: false,
  asyncResponse: false,
  promise: {
    resolve(_result: any) {},
    reject(_error: any) {},
  },
  initialState: {},
  options: null,
});

function push<T = any>(
  screen: any,
  initialState: any,
  options?: IModalOptions
): Promise<T> {
  state.options = options as IModalOptions;
  state.screen = screen;
  state.initialState = initialState;
  state.display = true;
  return new Promise((resolve, reject) => {
    state.promise.resolve = resolve;
    state.promise.reject = reject;
  });
}
function clear(): void {
  state.display = false;
  state.initialState = {};
}
function resolve(data: any = null): void {
  state.promise.resolve(data);
}
function reject(data: any = null): void {
  state.promise.reject(data);
}

export default () => ({
  state,
  push,
  clear,
  resolve,
  reject,
});
