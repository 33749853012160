import { NavigationGuardWithThis } from 'vue-router';
import useContext from '@/composables/useContext';

const { state } = useContext();

const partnerVerificationGuard: NavigationGuardWithThis<any> = async (
  to,
  from,
  next
) => {
  const isApproved = state.user?.companyIBAN;

  if (!to.meta.forApprovedClients) {
    /**
     *  if it is common route just allow to navigate
     *  */
    next();
    return;
  }

  if (!isApproved) {
    /**
     * if partner is not approved (means hadn't signed agreement yet)
     * navigate him to main page to sign documents
     */
    next('/');
    return;
  }

  next();
};

export default partnerVerificationGuard;
