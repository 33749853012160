import { getToken } from '@/utils/jwt';
import i18n from '@/plugins/i18n';
import axios, {
  CancelTokenSource,
  AxiosRequestConfig,
  CancelToken,
  AxiosRequestHeaders,
} from 'axios';

const languageCode: Record<string, string> = {
  uk: 'ukr',
  ru: 'rus',
  en: 'eng',
};

export const baseURI = process.env.VUE_APP_BASE_URL || '';
if (process.env.VUE_APP_BASE_URL === undefined) {
  console.warn('no BASE_UR provided');
}

export const prefix = '/api';

export class BaseAPI {
  private instance;

  controller = '';

  tokens: { [key: string]: CancelTokenSource } = {};

  constructor() {
    this.instance = axios.create({
      baseURL: baseURI + prefix,
    });

    this.instance.interceptors.request.use(function (config) {
      const headers: AxiosRequestHeaders = {
        ...config.headers,
        'Accept-Language': languageCode[i18n.global.locale.value],
      };

      const tocketData = getToken();
      if (tocketData) {
        headers.Authorization = `Bearer ${tocketData.accessToken}`;
      }

      return {
        ...config,
        headers,
      };
    });

    this.instance.interceptors.response.use(
      (response) => {
        if (response.data instanceof Blob) {
          return response;
        }
        if (response.data.result) {
          return response.data.data;
        }
        return Promise.reject(response.data.error);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  }

  cancelTocken(key: string): CancelToken {
    const source = this.tokens[key] ?? {};
    source?.cancel();
    this.tokens[key] = axios.CancelToken.source();
    return this.tokens[key].token;
  }

  _get<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
    return this.instance.request({
      url: this.controller + url,
      ...options,
      method: 'GET',
    });
  }

  _post<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
    return this.instance.request({
      url: this.controller + url,
      ...options,
      method: 'POST',
    });
  }

  _put<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
    return this.instance.request({
      url: this.controller + url,
      ...options,
      method: 'PUT',
    });
  }

  _patch<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
    return this.instance.request({
      url: this.controller + url,
      ...options,
      method: 'PATCH',
    });
  }

  _delete(url: string, options?: AxiosRequestConfig): Promise<void> {
    return this.instance.request({
      url: this.controller + url,
      ...options,
      method: 'DELETE',
    });
  }
}
