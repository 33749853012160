import { IPaginationData, IPaginationParams } from '@/interfaces/common';
import { ILocation, IStatus } from '@/interfaces/entities';
import { BaseAPI } from './base.api';

class LocationAPI extends BaseAPI {
  controller = '/location';

  add(data: Partial<ILocation>) {
    return this._post<ILocation>('', { data });
  }

  get(params: IPaginationParams = { page: 0, size: 0, availableOnly: true }) {
    return this._get<IPaginationData<ILocation>>('', { params });
  }

  update(uid: string, data: Partial<ILocation>) {
    return this._patch<ILocation>(`/${uid}`, { data });
  }

  delete(uid: string) {
    return this._delete(`/${uid}`);
  }

  statuses() {
    return this._get<IStatus[]>('/statuses');
  }

  imageAdd(uid: string, data: FormData) {
    return this._post<any>(`/${uid}/image/add`, { data });
  }

  imageRemove(uid: string) {
    return this._post<any>(`/${uid}/image/remove`);
  }
}

const api = new LocationAPI();
export default api;
