import { IPaginationData, IPaginationParams } from '@/interfaces/common';
import { IEntity, IStaff, IStatus } from '@/interfaces/entities';
import { BaseAPI } from './base.api';

class StaffAPI extends BaseAPI {
  controller = '/staff';

  add(data: Partial<IStaff>) {
    return this._post<IStaff>('', { data });
  }

  get(params: IPaginationParams = { page: 0, size: 0, availableOnly: true }) {
    return this._get<IPaginationData<IStaff>>('', { params });
  }

  update(uid: string, data: Partial<IStaff>) {
    return this._patch<IStaff>(`/${uid}`, { data });
  }

  delete(uid: string) {
    return this._delete(`/${uid}`);
  }

  statuses() {
    return this._get<IStatus[]>('/statuses');
  }

  contractTypes() {
    return this._get<IEntity[]>('/contractTypes');
  }

  positions() {
    return this._get<IEntity[]>('/positions');
  }

  qualifications() {
    return this._get<IEntity[]>('/qualifications');
  }

  imageAdd(uid: string, data: FormData) {
    return this._post<any>(`/${uid}/image/add`, { data });
  }

  imageRemove(uid: string) {
    return this._post<any>(`/${uid}/image/remove`);
  }
}

const api = new StaffAPI();
export default api;
