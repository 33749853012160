import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import i18n from '@/plugins/i18n';
import components from '@/plugins/components';
import '@/styles/index.scss';
import context from './composables/useContext';
import vClickOutside from 'click-outside-vue3';

const app = createApp(App)
  .use(vClickOutside)
  .use(components)
  .provide('context', context())
  .use(i18n)
  .use(router);

if (process.env.VUE_APP_ENABLE_ANALITICS === 'true')
  app.use(VueGtag, {
    config: { id: 'G-MSLTDLY9TQ' },
  });

app.mount('#app');
