import { IPaginationParams } from '../common';
import { ICrew, IEntity, ILocation, IUser } from '../entities';
import { EUserRole } from '../enums';
import { IRequest } from './request';

export enum EOrderGroup {
  ExtraFast,
  Full,
  Partial,
}

export enum EOrderStatus {
  New = 'new',
  Offered = 'offered',
  Ordered = 'ordered',
  WorkInProgress = 'workInProgress',
  WorkAcceptance = 'workAcceptance',
  Cancelling = 'cancelling',
  Cancelled = 'cancelled',
  Done = 'done',
  Dispute = 'dispute',
}

export enum EOrderDocumentStatus {
  New = 'new',
  OrderSignedByUser = 'orderSignedByUser',
  OrderSignedByPlatform = 'orderSignedByPlatform',
  ActSignedByUser = 'actSignedByUser',
  ActSignedByPlatform = 'actSignedByPlatform',
}

export enum EOrderPaymentStatus {
  New = 'new',
  PrepaymentRequired = 'prepaymentRequired',
  PrepaymentExpired = 'prepaymentExpired',
  PrepaymentPaid = 'prepaymentPaid',
  PrepaymentAccepted = 'prepaymentAccepted',
  PaymentRequired = 'paymentRequired',
  PaymentExpired = 'paymentExpired',
  PaymentPaid = 'paymentPaid',
  PaymentAccepted = 'paymentAccepted',
}

export enum EOrderWorklogDayStatus {
  New = 'new',
  Done = 'done',
  Dispute = 'dispute',
}

export interface IOffer extends IEntity {
  partnerId: string;
  crew: string[];
  crews: ICrew[];
  booking: {
    crew: ICrew;
    finishAt: string;
    startAt: string;
    updatedAt: string;
  }[];
  area: number;
  days: number;
  startAt: string;
  finishAt: string;
  locationId: string;
  location: ILocation;
  partner: IUser;
  pricePerSquare: string;
  priceFare: string;
  distance: string;
  isConfirmed: boolean;
}
export interface IUpdateOffer {
  crew: { uid: string; startAt: string; finishAt: string }[];
  area: number;
  pricePerSquare: number;
  priceFare: number;
  distance: number;
}
export interface ICreateOrder {
  requestId: string;
  offers: IOffer[];
}

export interface IOrder {
  area: number;
  createdAt: string;
  days: number;
  distance: number;
  finishAt: string;
  id: number;
  isActive: boolean;
  priceFare: string;
  priceIdle: string;
  pricePerSquare: string;
  squarePerDay: string;
  startAt: string;
  statusUpdatedAt: string;
  totalCost: number;
  fareCost: number;
  uid: string;
  updatedAt: string;
  workingHours: number;
  partner: IUser;
  client: IUser;
  crews: ICrew[];
  cancelReason: string;
  request: IRequest;
  group: EOrderGroup;
  number: number;

  priceFareWithVAT: string;
  priceIdleWithVAT: string;
  pricePerSquareWithVAT: string;

  status: EOrderStatus;
  clientDocumentsStatus: EOrderDocumentStatus;
  partnerDocumentsStatus: EOrderDocumentStatus;
  paymentStatus: EOrderPaymentStatus;
}

export interface IGetOrdersParams extends IPaginationParams {
  cropManagementOperationId: string;
  startAt: string;
  finishAt: string;
  asRole: EUserRole;
  requestId: string;
  requestNumber: number;
}

export interface ICreateOrderResponse {
  orders: IOrder[];
  request: IRequest;
}

export interface IWorklogItem extends IEntity {
  area: number;
  closedAt: string;
  cost: string;
  date: string;
  idle: number;
  idleCost: string;
  distance: number;
  isClosedByClient: boolean;
  isClosedByPartner: boolean;
  clientComment: string;
  partnerComment: string;
  status: EOrderWorklogDayStatus;
}

export interface IWorklog {
  [date: string]: {
    [uid: string]: IWorklogItem;
  };
}

export interface IOrderClose {
  area: number;
  idle: number;
  comment: string;
  openDispute: boolean;
}

export interface IOrderConfirm {
  comment: string;
  openDispute: boolean;
}

export interface IInvoiceInfo {
  amount: number;
  paidAmount: number;
  prePaymentPercent: number;
}

export interface IStatisticsItem {
  amount: number;
  count: number;
}

export interface IStatistics {
  paymentAccepted: IStatisticsItem;
  workInProgress: IStatisticsItem;
  done: IStatisticsItem;
  cancelled: IStatisticsItem;
}
