
import type { FunctionalComponent } from 'vue';

const DateTime: FunctionalComponent<{
  date?: Date | string;
  dates?: string[];
  format?: string;
}> = ({ date = new Date(), dates, format = 'DD.MM.YYYY, hh:mm:ss' }) => {
  function formatDate(date: string | Date) {
    const newFormatDate = new Date(date);
    const DD = `${newFormatDate.getDate()}`.padStart(2, '0');
    const MM = `${newFormatDate.getMonth() + 1}`.padStart(2, '0');
    const YY = `${newFormatDate.getFullYear()}`.padStart(4, '0');
    const YYYY = `${newFormatDate.getFullYear()}`.padStart(4, '0');
    const hh = `${newFormatDate.getHours()}`.padStart(2, '0');
    const mm = `${newFormatDate.getMinutes()}`.padStart(2, '0');
    const ss = `${newFormatDate.getSeconds()}`.padStart(2, '0');
    return format
      .replaceAll('DD', DD)
      .replaceAll('MM', MM)
      .replaceAll('YYYY', YYYY)
      .replaceAll('YY', YY)
      .replaceAll('hh', hh)
      .replaceAll('mm', mm)
      .replaceAll('ss', ss);
  }

  function valueString() {
    if (dates) {
      if (formatDate(dates[0]) === formatDate(dates[1])) {
        return formatDate(dates[0]);
      } else {
        return `${formatDate(dates[0])} - ${formatDate(dates[1])}`;
      }
    } else {
      return formatDate(date);
    }
  }

  return valueString();
};

export default DateTime;
