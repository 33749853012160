/* eslint-disable @typescript-eslint/no-empty-function */
import {
  reactive,
  readonly,
  DeepReadonly,
  computed,
  ComputedRef,
  ref,
  Ref,
} from 'vue';
import useMessage from './useMessage';
import { IMessage } from '../components/layout/snack-bar';
import { IDocument, IProfileSettings, IUser } from '../interfaces/entities';
import AuthAPI from '@/api/auth.api';
import ProfileAPI from '@/api/profile.api';
import { clearToken, getToken } from '../utils/jwt';
import DocumentAPI from '@/api/document.api';
import useWindow from './useWindow';

const { isDesktop } = useWindow();
const {
  requestError,
  mesagges: MessageState,
  ...MessageActions
} = useMessage();
interface IState {
  user: IUser | null;
  isUserLoading: boolean;
  messages: IMessage[];
  isSidebarOpeneded: boolean;
}

export interface IContext {
  state: DeepReadonly<IState>;
  isClient: ComputedRef<boolean>;
  agreement: Ref<IDocument>;
  settings: Ref<IProfileSettings>;
  setUser(user: IUser): void;
  getUser(): Promise<void>;
  refresh(): Promise<void>;
  toggleSidebar(forceHide?: boolean): void;
  logOut(): void;
  getAgreement(): void;
  message: typeof MessageActions;
}

const state = reactive({
  user: null,
  messages: MessageState,
  isUserLoading: false,
  isSidebarOpeneded: isDesktop.value,
} as IState);

const agreement = ref<IDocument>({} as IDocument);
const isClient = computed(
  () => !!(state.user?.isClient && !state.user.isPartner)
);
const settings = ref({} as IProfileSettings);

function getAgreement() {
  if (!state.user?.isPartner) return Promise.resolve();
  return DocumentAPI.agreementPartner().then((_d) => {
    agreement.value = _d;
  });
}

function getSettings() {
  return ProfileAPI.settings().then((_settings) => {
    settings.value = _settings;
  });
}

function setUser(user: IUser): void {
  state.user = user;
}

async function getUser(): Promise<any> {
  state.isUserLoading = true;

  return AuthAPI.me()
    .then((user) => {
      setUser(user);
      return Promise.all([getAgreement(), getSettings()]);
    })
    .finally(() => {
      state.isUserLoading = false;
    });
}

async function refresh(): Promise<void> {
  state.isUserLoading = true;
  const tokenData = getToken()!;
  return AuthAPI.refresh({
    refreshToken: tokenData.refreshToken || tokenData.newRefreshToken,
  })
    .catch(requestError)
    .finally(() => {
      state.isUserLoading = false;
    });
}

function toggleSidebar(force?: boolean): void {
  if (typeof force === 'boolean') {
    state.isSidebarOpeneded = force;
  } else {
    state.isSidebarOpeneded = !state.isSidebarOpeneded;
  }
}

function logOut(): void {
  clearToken();
  location.href = '/';
}

export default (): IContext => ({
  state: readonly(state),
  isClient,
  agreement,
  settings,
  getAgreement,
  setUser,
  getUser,
  refresh,
  toggleSidebar,
  logOut,
  message: MessageActions,
});

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    context: IContext;
  }
}
