import { NavigationGuardWithThis } from 'vue-router';
import useContext from '@/composables/useContext';

const { state } = useContext();

const roleGuard: NavigationGuardWithThis<any> = async (to, from, next) => {
  const isPartner = state.user?.isPartner;
  const isClient = state.user?.isClient;

  if (to.meta.clientOnly && !isClient) {
    return next('/');
  }

  if (to.meta.partnerOnly && !isPartner) {
    return next('/');
  }

  next();
};

export default roleGuard;
