import { RouteRecordRaw } from 'vue-router';
import Router from './router.vue';
import parkContext from './context';

export default [
  {
    path: 'park',
    component: Router,
    meta: {
      withAuth: true,
      forApprovedPartners: true,
      partnerOnly: true,
    },
    children: [
      {
        path: '',
        component: (): Promise<typeof import('*.vue')> =>
          import(/* webpackChunkName: "park" */ './pages/index/index.vue'),
        meta: {
          header: 'page.park',
        },
      },
      {
        path: 'add',
        component: (): Promise<typeof import('*.vue')> =>
          import(
            /* webpackChunkName: "park-add" */ './pages/add-resource/AddResource.vue'
          ),
        meta: {
          header: 'page.parkAdd',
        },
      },
      {
        path: 'edit',
        component: (): Promise<typeof import('*.vue')> =>
          import(
            /* webpackChunkName: "park-edit" */ './pages/add-resource/AddResource.vue'
          ),
        meta: {
          header: 'page.parkEdit',
        },
        beforeEnter(_, __, next) {
          if (!parkContext.state.edittingItem) {
            return next('/park');
          }
          return next();
        },
      },
    ],
  },
] as Array<RouteRecordRaw>;
