export const isTopMenu = true;

export const menu = [
  {
    label: 'sidebar.item.main',
    link: `/`,
    icon: 'cottage',
  },
  {
    label: 'sidebar.item.orders',
    link: `/orders`,
    icon: 'fact_check',
  },
  {
    label: 'sidebar.item.documents',
    link: `/documents`,
    icon: 'folder_open',
  },
  {
    label: 'sidebar.item.bills',
    link: `/bills`,
    icon: 'payments',
  },
  {
    label: 'sidebar.item.technics',
    link: `/park`,
    icon: 'agriculture',
    roles: 'partner',
  },
  {
    label: 'sidebar.item.calendar',
    link: `/booking`,
    icon: 'event',
    roles: 'partner',
  },
];
