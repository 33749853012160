import { IFile, IPaginationData, IPaginationParams } from '@/interfaces/common';
import { IDocument } from '@/interfaces/entities';
import {
  ICreateOrderResponse,
  IInvoiceInfo,
  IOffer,
  IUpdateOffer,
  IWorklog,
} from '@/interfaces/models/order';
import {
  ERequestStatusesUpdater,
  IRequest,
  IRequestResponse,
  IStatusChange,
} from '@/interfaces/models/request';
import { BaseAPI } from './base.api';

class RequestAPI extends BaseAPI {
  controller = '/request';

  getUid(data: Partial<IRequest>) {
    return this._post<string>('', { data });
  }

  search(uid: string) {
    return this._get<IRequestResponse>(`/${uid}/search`);
  }

  get(params: IPaginationParams) {
    return this._get<IPaginationData<IRequest>>('', { params });
  }

  getByUid(uid: string) {
    return this._get<IRequest>(`/${uid}`);
  }

  orders(uid: string) {
    return this._get<ICreateOrderResponse>(`/${uid}/orders`);
  }

  documents(uid: string) {
    return this._get<IDocument[]>(`/${uid}/documents`);
  }

  getFiles(uid: string) {
    return this._get<IFile[]>(`/${uid}/files`);
  }

  addFiles(uid: string, data: FormData) {
    return this._post<File[]>(`/${uid}/files/add`, { data });
  }

  invoiceInfo(uid: string) {
    return this._get<IInvoiceInfo>(`/${uid}/invoiceInfo`);
  }

  worklog(uid: string) {
    return this._get<IWorklog>(`/${uid}/worklog`);
  }

  status(uid: string, status: ERequestStatusesUpdater) {
    return this._post<IStatusChange>(`/${uid}/status/${status}`);
  }

  statusCancel(uid: string, comment: string) {
    return this._post<IStatusChange>(`/${uid}/status/cancel`, {
      data: { comment },
    });
  }

  crops() {
    return this._get<any>('/crops');
  }

  cropsManagementOperations() {
    return this._get<any>(`/cropsManagementOperations`);
  }

  getOffer(uid: string) {
    return this._get<IOffer>(`/${uid}/offer`);
  }

  addOffer(uid: string, data: IOffer) {
    return this._post<any>(`/${uid}/offer`, { data });
  }

  editOffer(uid: string, data: IUpdateOffer) {
    return this._patch<any>(`/${uid}/offer`, { data });
  }

  addOrder(uid: string) {
    return this._post<any>(`/${uid}/order`);
  }
}

const api = new RequestAPI();
export default api;
