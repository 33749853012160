export enum EDocumentTypes {
  WorkOrderClient = 'workOrderClient',
  WorkOrderPartner = 'workOrderPartner',
  PrepaymentBill = 'prepaymentBill',
  AcceptanceActClient = 'acceptanceActClient',
  AcceptanceActPartner = 'acceptanceActPartner',
  PaymentBill = 'paymentBill',
  platformAgreementPartner = 'platformAgreementPartner',
  platformAgreementClient = 'platformAgreementClient',
}

export enum EDocumentType {
  Document = 'document',
  Bill = 'bill',
}

export enum EUserRole {
  Client = 'client',
  Partner = 'partner',
  Admin = 'admin',
}

export enum ESignStatus {
  Signed = 'signed',
  Unsigned = 'unsigned',
}

export enum EBillStatus {
  New = 'new',
  Paid = 'paid',
  Accepted = 'accepted',
  Expired = 'expired',
  Cancelled = 'cancelled',
}

export enum ETransactionStatus {
  New = 'new',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Done = 'done',
}

export enum ETransactionOperation {
  Withdraw = 'withdraw',
  Payment = 'payment',
  Refund = 'refund',
}
