import { ERequestStatuses, IRequest } from '@/interfaces/models/request';
import { computed, readonly, ref } from 'vue';
import RequestAPI from '@/api/request.api';
import useMessage from '@/composables/useMessage';
import { IOffer } from '@/interfaces/models/order';
import useContext from '@/composables/useContext';

const { state } = useContext();
const { requestError, warning } = useMessage();
const request = ref<IRequest>(null!);
const offer = ref<IOffer>(null!);
const loading = ref(true);
const isPartner = computed(() => state.user?.uid === offer.value?.partner?.uid);
const isClient = computed(() => !isPartner.value);
const requestStatus = computed(() => ({
  isNew: request.value.status === ERequestStatuses.New,
  isOffered: request.value.status === ERequestStatuses.Offered,
}));

function getRequest(uid: string) {
  return RequestAPI.getByUid(uid).then((e) => {
    request.value = e;
  });
}
function getOffer(uid: string) {
  RequestAPI.getOffer(uid)
    .then((e) => {
      offer.value = e;
    })
    .catch(requestError);
}

function downloadFiles() {
  return RequestAPI.getFiles(request.value.uid).then((files) => {
    if (!files.length) {
      warning('Файли відсутні!');
    }
    files.forEach((file) => {
      const link = document.createElement('a');
      link.href = file.fileUrl;
      link.setAttribute('download', file.filename);
      document.body.appendChild(link);
      link.click();
    });
  });
}

export default function () {
  return {
    loading,
    request,
    offer,
    isPartner,
    isClient,
    requestStatus,
    getOffer,
    getRequest,
    downloadFiles,
  };
}
