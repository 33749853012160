import { NavigationGuardWithThis } from 'vue-router';
import { getToken, isExpired } from '@/utils/jwt';
import useContext from '@/composables/useContext';

const { state, getUser, refresh } = useContext();

const authGuard: NavigationGuardWithThis<any> = async (to, from, next) => {
  if (!to.meta.withAuth) {
    /**
     * if no auth required
     * just allow to navigate
     */
    next();
    return;
  }

  const tokenData = getToken();

  try {
    if (!tokenData) {
      localStorage.backUrl = to.path;
      throw '';
    } else if (isExpired()) {
      await refresh();
    }

    if (!state.user) {
      await getUser();
    }
  } catch {
    next('/auth/sign-in');
    return;
  }

  if (!localStorage.backUrl) {
    next();
    return;
  }

  const link = localStorage.backUrl;
  localStorage.backUrl = '';
  next(link);
};

export default authGuard;
