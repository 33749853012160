export const isTopMenu = false;

export const menu = [
  {
    label: 'sidebar.item.orders',
    link: `/orders`,
    icon: 'fact_check',
  },
  {
    label: 'sidebar.item.documents',
    link: `/documents`,
    icon: 'folder_open',
  },
  {
    label: 'sidebar.item.bills',
    link: `/bills`,
    icon: 'payments',
  },
  {
    label: 'sidebar.item.users',
    link: `/users`,
    icon: 'people_alt',
  },
  {
    label: 'sidebar.item.admin',
    link: `/admin`,
    icon: 'admin_panel_settings',
  },
];
