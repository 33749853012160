import { IPaginationData, IPaginationParams } from '@/interfaces/common';
import { IDocument } from '@/interfaces/entities';
import { IGetDocumentsParams } from '@/interfaces/models/document';
import { AxiosResponse } from 'axios';
import { BaseAPI } from './base.api';

class DocumentAPI extends BaseAPI {
  controller = '/document';

  get(params: IGetDocumentsParams) {
    return this._get<IPaginationData<IDocument>>('', { params });
  }

  byUid(uid: string) {
    return this._get<IDocument>(`/${uid}`);
  }

  download(uid: string) {
    return this._get<AxiosResponse<Blob>>(`/${uid}/download`, {
      responseType: 'blob',
    }).then((response) => response.data);
  }

  signed(uid: string, data: FormData) {
    return this._post<IDocument>(`/${uid}/signed`, { data });
  }

  agreementClient() {
    return this._get<IDocument>(`/agreement/client`);
  }

  agreementPartner() {
    return this._get<IDocument>(`/agreement/partner`);
  }

  bills(params: IPaginationParams = { page: 0, size: 20 }) {
    return this._get<IPaginationData<IDocument>>(`/bills`, { params });
  }
}

const api = new DocumentAPI();
export default api;
