import {
  EOrderDocumentStatus,
  EOrderPaymentStatus,
  EOrderStatus,
  EOrderWorklogDayStatus,
  IInvoiceInfo,
  IOrder,
  IOrderClose,
  IOrderConfirm,
  IWorklog,
  IWorklogItem,
} from '@/interfaces/models/order';
import {
  ERequestStatuses,
  ERequestStatusesUpdater,
  IRequest,
} from '@/interfaces/models/request';
import { computed, reactive, readonly, ref } from 'vue';
import OrderAPI from '@/api/order.api';
import RequestAPI from '@/api/request.api';
import useContext from '@/composables/useContext';
import { IDocument } from '@/interfaces/entities';
import { IPaginationParams } from '@/interfaces/common';
import useMessage from '@/composables/useMessage';
import { BookingAlert } from '@/components/functional/modal-screens/actions';

export interface IOrderTemplate {
  order: IOrder;
  worklog: IWorklogItem;
  percentage: number;
  completedPercentage: number;
  squarePerDay: number;
}
export interface IWorkLogTemplate {
  [key: string]: {
    [key: string]: IOrderTemplate;
  };
}

const { state } = useContext();
const { requestError } = useMessage();
const isCompanyCompleted = ref(true);
const isDocumentsCompleted = ref(true);
const orders = ref<IOrder[]>([]);
const request = ref<IRequest>(null!);
const worklog = reactive<IWorklog>({});
const documents = ref<IDocument[]>([]);
const invoiceInfo = ref<IInvoiceInfo>(null!);
const loading = ref(false);
const documentsLoading = ref(false);
const isPartner = computed(() => state.user?.uid !== request.value?.user?.uid);
const isClient = computed(() => !isPartner.value);
const forceDocumentsStatus = ref();
const forcePaymentStatus = ref();
const forceStatus = ref();
const forceCancelReason = ref('');
const cancelReason = computed(
  () => forceCancelReason.value || orders.value[0]?.cancelReason
);
const isDayBeforeStart = computed(
  () =>
    new Date(request.value.startAt).getTime() - new Date().getTime() <
    24 * 60 * 60 * 1000
);

const clientDocumentsStatus = computed(
  () => orders.value[0]?.clientDocumentsStatus
);
const partnerDocumentsStatus = computed(
  () => orders.value[0]?.partnerDocumentsStatus
);
const documentsStatus = computed(
  () =>
    forceDocumentsStatus.value ||
    (isPartner.value
      ? orders.value[0]?.partnerDocumentsStatus
      : orders.value[0]?.clientDocumentsStatus)
);
const paymentStatus = computed(
  () => forcePaymentStatus.value || orders.value[0]?.paymentStatus
);
const status = computed(() => forceStatus.value || orders.value[0]?.status);

const requestStatus = computed(() => ({
  isNew: request.value.status === ERequestStatuses.New,
  isOffered: request.value.status === ERequestStatuses.Offered,
}));

const statusFlags = computed(() => ({
  isNew: status.value === EOrderStatus.New,
  isOffered: status.value === EOrderStatus.Offered,
  isOrdered: status.value === EOrderStatus.Ordered,
  isCanceled: status.value === EOrderStatus.Cancelled,
  isCancelling: status.value === EOrderStatus.Cancelling,
  isWorkInProgress: status.value === EOrderStatus.WorkInProgress,
  isWorkAcceptance: status.value === EOrderStatus.WorkAcceptance,
  isDone: status.value === EOrderStatus.Done,
  isDispute: status.value === EOrderStatus.Dispute,
  isNewDocument: documentsStatus.value === EOrderDocumentStatus.New,
  isOrderSignedByUser:
    documentsStatus.value === EOrderDocumentStatus.OrderSignedByUser,
  isOrderSignedByPlatform:
    documentsStatus.value === EOrderDocumentStatus.OrderSignedByPlatform,
  isActSignedByUser:
    documentsStatus.value === EOrderDocumentStatus.ActSignedByUser,
  isActSignedByPlatform:
    documentsStatus.value === EOrderDocumentStatus.ActSignedByPlatform,
  isNewPayment: paymentStatus.value === EOrderPaymentStatus.New,
  isPrepaymentRequired:
    paymentStatus.value === EOrderPaymentStatus.PrepaymentRequired,
  isPrepaymentPaid: paymentStatus.value === EOrderPaymentStatus.PrepaymentPaid,
  isPrepaymentAccepted:
    paymentStatus.value === EOrderPaymentStatus.PrepaymentAccepted,
  isPaymentRequired:
    paymentStatus.value === EOrderPaymentStatus.PaymentRequired,
  isPaymentPaid: paymentStatus.value === EOrderPaymentStatus.PaymentPaid,
  isPaymentAccepted:
    paymentStatus.value === EOrderPaymentStatus.PaymentAccepted,
}));

const worklogTemplate = computed(() => {
  let isReady = true;
  let isDisput = false;

  function getSquarePerDay(o: IOrder) {
    return +o.squarePerDay > request.value.area
      ? request.value.area
      : +o.squarePerDay;
  }

  const list = Object.fromEntries(
    Object.entries(worklog).map(([date, _worklog], i, arr) => {
      const totalPerDay = orders.value.reduce(
        (a, c) => a + (_worklog[c.uid]?.area || +getSquarePerDay(c)),
        0
      );

      return [
        date,
        Object.fromEntries(
          orders.value.map((_order) => {
            const currentWorklog = _worklog[_order.uid] || {};
            if (currentWorklog.status !== EOrderWorklogDayStatus.Done) {
              isReady = false;
            }
            if (currentWorklog.status === EOrderWorklogDayStatus.Dispute) {
              isDisput = true;
            }

            return [
              _order.uid,
              {
                order: _order,
                worklog: currentWorklog,
                squarePerDay:
                  /**
                   * Should be displayed actualy closed square
                   * Or planned square a day
                   */
                  currentWorklog.area || Math.round(getSquarePerDay(_order)),
                percentage:
                  /**
                   * Should be displayed actualy closed square
                   * Or planned square a day
                   */
                  Math.round((currentWorklog.area / totalPerDay) * 100) ||
                  Math.round((getSquarePerDay(_order) / totalPerDay) * 100),
              },
            ];
          })
        ),
      ];
    })
  );

  return { list, isReady, isDisput };
});

function setOrders(_orders: IOrder[], request?: IRequest): void {
  if (!request) {
    orders.value = _orders;
    return;
  }
  if (!_orders.length) {
    orders.value = [];
    return;
  }
  /**
   * each order contains max possible area and total cost calcilated based on it
   * so on client side we need to detect the last of partner
   * and decrease area and cost to cover only area that is not
   * covered by previous partners
   */
  let covered = 0;

  orders.value = _orders.map((item) => {
    if (covered + +item.area > request.area) {
      const area = request.area - covered < 0 ? 0 : request.area - covered;
      const totalCost = area * +item.squarePerDay + +item.fareCost;
      covered += +item.area;
      return {
        ...item,
        area,
        totalCost: totalCost,
      };
    }

    covered += +item.area;
    return item;
  });
}
function setRequest(_request: IRequest): void {
  request.value = _request;
}
function getRequests(form: IPaginationParams) {
  return RequestAPI.get(form).catch(requestError);
}
function getOrder(uid: string): Promise<void> {
  return RequestAPI.orders(uid)
    .then(({ request, orders }) => {
      setRequest(request);
      return Promise.all(orders.map((order) => OrderAPI.getById(order.uid)));
    })
    .then((orders) => {
      setOrders(orders);
    })
    .catch(requestError);
}
function getWorklog(): Promise<void> {
  return RequestAPI.worklog(request.value.uid)
    .then((_worklog) => {
      Object.assign(worklog, _worklog);
    })
    .catch(requestError);
}
function getDoucments() {
  documentsLoading.value = true;
  return RequestAPI.documents(request.value.uid)
    .then((_documents) => {
      documents.value = _documents;
    })
    .catch(requestError)
    .finally(() => {
      documentsLoading.value = false;
    });
}
function getInvoiceInfo() {
  if (!isClient.value) return;
  return RequestAPI.invoiceInfo(request.value.uid)
    .then((invoice) => {
      invoiceInfo.value = invoice as any;
    })
    .catch(requestError);
}
function closeDate(
  uid: string,
  date: string,
  data: IOrderClose
): Promise<void> {
  return OrderAPI.orderClose(uid, date, data)
    .then((worklogItem) => {
      worklog[date][uid] = worklog[date][uid] ?? {};
      Object.assign(worklog[date][uid], worklogItem);
    })
    .catch(requestError);
}
function confirmDate(
  uid: string,
  date: string,
  data: IOrderConfirm
): Promise<void> {
  return OrderAPI.orderConfirm(uid, date, data)
    .then((worklogItem) => {
      worklog[date][uid] = worklog[date][uid] ?? {};
      Object.assign(worklog[date][uid], worklogItem);
    })
    .catch(requestError);
}

/**
 * Status updates
 */
const statusLoaders = reactive({
  signDocuments: false,
  prepaymentPaid: false,
  cancelRequest: false,
  acceptWork: false,
  paymentPaid: false,
});

function changeStatus(
  status: ERequestStatusesUpdater,
  loaderKey: keyof typeof statusLoaders
) {
  statusLoaders[loaderKey] = true;
  return RequestAPI.status(request.value.uid, status)
    .catch((error) => {
      console.log(error.response.status);
      if (error.response.status === 412) {
        BookingAlert({ onCancel: cancelRequest });
      } else {
        requestError(error);
      }
    })
    .finally(() => (statusLoaders[loaderKey] = false));
}
function signDocuments() {
  return changeStatus(
    ERequestStatusesUpdater.DocumentsSigned,
    'signDocuments'
  ).then(
    () => (forceDocumentsStatus.value = EOrderDocumentStatus.OrderSignedByUser)
  );
}
function prepaymentPaid() {
  return changeStatus(
    ERequestStatusesUpdater.PaymentPaid,
    'prepaymentPaid'
  ).then(() => (forcePaymentStatus.value = EOrderPaymentStatus.PrepaymentPaid));
}
function paymentPaid() {
  return changeStatus(ERequestStatusesUpdater.PaymentPaid, 'paymentPaid').then(
    () => (forcePaymentStatus.value = EOrderPaymentStatus.PaymentPaid)
  );
}
function cancelRequest(reason: string) {
  statusLoaders.cancelRequest = true;
  return RequestAPI.statusCancel(request.value.uid, reason)
    .then(() => {
      forceCancelReason.value = reason;
      forceStatus.value = EOrderStatus.Cancelling;
    })
    .catch(requestError)
    .finally(() => (statusLoaders.cancelRequest = false));
}
function acceptWork() {
  return changeStatus(ERequestStatusesUpdater.Accept, 'acceptWork').then(() => {
    getDoucments();
    forceDocumentsStatus.value = EOrderDocumentStatus.New;
    forcePaymentStatus.value = EOrderPaymentStatus.New;
    forceStatus.value = EOrderStatus.WorkAcceptance;
  });
}

export default function () {
  return {
    clientDocumentsStatus,
    partnerDocumentsStatus,
    isDayBeforeStart,
    status,
    statusFlags,
    requestStatus,
    isCompanyCompleted: readonly(isCompanyCompleted),
    isDocumentsCompleted: readonly(isDocumentsCompleted),
    loading,
    documentsLoading,
    worklogTemplate,
    orders: readonly(orders),
    request: readonly(request),
    documents: readonly(documents),
    worklog: readonly(worklog),
    invoiceInfo: readonly(invoiceInfo),
    isPartner,
    isClient,
    cancelReason,
    statusLoaders: readonly(statusLoaders),
    setOrders,
    setRequest,
    getOrder,
    getRequests,
    getWorklog,
    closeDate,
    confirmDate,
    getDoucments,
    getInvoiceInfo,
    signDocuments,
    prepaymentPaid,
    paymentPaid,
    cancelRequest,
    acceptWork,
  };
}
